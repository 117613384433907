<template>
    <div>
        <card-header title="Duplicate Itinerary" icon="fa-copy"/>

        <card-body>
            <card-list>
                <subheader-list-item icon="fa-align-left" title="Duplicate to Trip"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-select expanded v-model="dup.trip_id">
                                <option v-for="t in trips" :value="t.id">{{t.title}}</option>
                            </b-select>
                        </div>
                    </div>
                </container-list-item>
                <subheader-list-item icon="fa-align-left" title="Duplication Options"/>

                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="dup.events">Events</b-switch>
                        </div>
                    </div>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!dup.trip_id" @click="submit"><i class="fas fa-plus mr-3"></i>Duplicate Itinerary</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardBody from "@/TIER/components/CardBody";
    import TripForm from "@/cards/trips/TripForm";
    import CardFooter from "@/TIER/components/CardFooter";

    import {client as http} from '../../http_client';
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        props: ['card', 'props'],
        components: {ContainerListItem, SubheaderListItem, CardList, CardFooter, TripForm, CardBody, CardHeader},
        data: function() {
            return {
                trips: [],
                dup: {
                    events: true
                }
            };
        },
        methods: {
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/active/0/500', {force: true}).then(response => {
                    this.trips = response.data.trips;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/' + this.props.tripId + '/days/duplicate', {
                    trip_id: this.dup.trip_id,
                    events: this.dup.events
                }, {force: true}).then(response => {
                    this.$reloadCard('trips');
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>
